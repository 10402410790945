<template>
  <section class="m-suggest m-scroll">

    <!-- 头部导航 -->
    <m-header title="平台反馈" ></m-header>

    <div class="main">

      <div @click="handleSelect(1)" class="suggest-cell van-hairline--bottom">
        <div class="label">
          <img src="@/assets/suggest/icon_anzhuang@2x.png" alt="">
          <span>售后、安装与发票问题</span>
        </div>
        <img class="back"   src="@/assets/public/icon-fanhui@2x.png" alt="">
      </div>

      <div @click="handleSelect(2)" class="suggest-cell van-hairline--bottom">
        <div class="label">
          <img src="@/assets/suggest/icon_zhiliang@2x.png" alt="">
          <span>商品使用与质量</span>
        </div>
        <img class="back"   src="@/assets/public/icon-fanhui@2x.png" alt="">
      </div>

      <div @click="handleSelect(3)" class="suggest-cell van-hairline--bottom">
        <div class="label">
          <img src="@/assets/suggest/tousu@2x.png" alt="">
          <span>投诉与建议</span>
        </div>
        <img class="back"   src="@/assets/public/icon-fanhui@2x.png" alt="">
      </div>

      <div @click="handleSelect(4)" class="suggest-cell van-hairline--bottom">
        <div class="label">
          <img src="@/assets/suggest/icon_huodong@2x.png" alt="">
          <span>购买活动与账号问题</span>
        </div>
        <img class="back"   src="@/assets/public/icon-fanhui@2x.png" alt="">
      </div>

      <div @click="handleSelect(5)" class="suggest-cell">
        <div class="label">
          <img src="@/assets/suggest/icon_qita@2x.png" alt="">
          <span>其他</span>
        </div>
        <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
      </div>

      <div @click="handleSelect(6)" class="suggest-cell suggest-margin">
        <div class="label">
          <img src="@/assets/suggest/icon_app@2x.png" alt="">
          <span>APP使用体验问题与建议</span>
        </div>
        <img class="back"   src="@/assets/public/icon-fanhui@2x.png" alt="">
      </div>

     

    </div>







    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'MSuggest',
  components:{ MHeader },

  methods:{

    // 退出当前账号
    handleSelect(type){
      this.$router.push({path:`/zh/suggest/submit/${type}`})
    },
   
  },
}
</script>

<style lang="less" scoped>
@import './suggest.less';
</style>